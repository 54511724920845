import React, {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import BrandSlider from "./BrandSlider";

const brandSliders = document.getElementsByClassName("brand-slider");
if (brandSliders.length > 0) {
    for (let brandSlider of brandSliders) {
        let images = brandSlider.getAttribute('data-images');
        if (images.length > 0) {
            images = JSON.parse(images);
            const root = createRoot(brandSlider);
            root.render(<StrictMode>
                <BrandSlider images={images}/>
            </StrictMode>);
        }
    }
}
