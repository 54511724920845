import Slider from "react-slick";

export default function BrandSlider({images}) {

    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 0,
        cssEase: "linear",
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    autoplay: true,
                    speed: 3000,
                    autoplaySpeed: 0,
                    cssEase: "linear",
                    arrows: false,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    autoplay: true,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    speed: 3000,
                    autoplaySpeed: 0,
                    cssEase: "linear",
                    arrows: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    autoplay: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    speed: 3000,
                    autoplaySpeed: 0,
                    cssEase: "linear",
                    arrows: false,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    autoplay: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    speed: 3000,
                    autoplaySpeed: 0,
                    cssEase: "linear",
                    arrows: false,
                }
            }
        ]
    };
    return (
        <Slider {...settings}>
            {
                images.map((image) => {
                    return <div key={image.url}><img className="img-fluid component-image" src={image.url}
                                                     alt="brand-logo"/>
                    </div>
                })
            }
        </Slider>
    );
}
